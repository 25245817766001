import React, {useEffect, useRef, useState} from 'react'
import './style.css'

const BuyersPage = () => {
  const headers: any = [
    {
      name: 'head1',
      width: 150,
      fixed: true,
      handler: function (elem) {
        return <h4 style={{color: 'green'}}>{elem}</h4>
      },
    },
    {
      name: 'head2',
      width: 60,
      fixed: true,
    },
    {
      name: 'head3',
      width: 100,
      fixed: true,
    },
    {
      name: 'head4',
      width: 100,
    },
    {
      name: 'head5',
      width: 100,
    },
    {
      name: 'head6',
      width: 100,
      handler: function (elem) {
        return <h3>{elem}</h3>
      },
    },
    {
      name: 'head7',
      width: 100,
    },
    {
      name: 'head8',
      width: 100,
    },
    {
      name: 'head9',
      width: 100,
    },
    {
      name: 'head10',
      width: 100,
    },
    {
      name: 'head11',
      width: 100,
    },
    {
      name: 'head12',
      width: 100,
    },
    {
      name: 'head13',
      width: 100,
    },
    {
      name: 'head14',
      width: 100,
    },
    {
      name: 'head15',
      width: 100,
    },
    {
      name: 'head16',
      width: 100,
    },
    {
      name: 'head17',
      width: 100,
    },
    {
      name: 'head18',
      width: 100,
    },
    {
      name: 'head19',
      width: 100,
    },
    {
      name: 'head20',
      width: 100,
    },
    {
      name: 'head21',
      width: 100,
    },
    {
      name: 'head22',
      width: 100,
    },
    {
      name: 'head23',
      width: 100,
    },
    {
      name: 'head24',
      width: 100,
    },
    {
      name: 'head25',
      width: 100,
    },
    {
      name: 'head26',
      width: 100,
    },
  ]

  const data = [
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
    {
      data1: 'Привет !',
      data2: 'data',
      data3: 'data3',
      data4: 'data4',
      data5: 'data5',
      data6: 'data6',
      data7: 'data7',
      data8: 'data8',
      data9: 'data9',
      data10: 'data10',
      data11: 'data10',
      data12: 'data10',
      data13: 'data10',
      data14: 'data10',
      data15: 'data10',
      data16: 'data10',
      data17: 'data10',
      data18: 'data10',
      data19: 'data10',
      data20: 'data10',
      data21: 'data10',
      data22: 'data10',
      data23: 'data10',
      data24: 'data10',
      data25: 'data10',
      data26: 'data10',
    },
  ]

  const itemsRef = useRef<any>([])
  // you can access the elements with itemsRef.current[n]

  useEffect(() => {
    //itemsRef.current = itemsRef.current.slice(0, data);

    itemsRef.current.map((item) => {
      console.log(item)
    })

    Object.keys(data[0]).forEach((key) => {
      console.log(key, data[0][key])
    })
  }, [])

  let zz = 0
  let uu = 0

  return (
    <>
      <div className={'custom-fixed-table'}>
        <table>
          <thead>
            <tr>
              {headers.map((item, index) => {
                {
                  uu = headers[index].fixed
                    ? index
                      ? (uu += headers[index - 1].width)
                      : (uu += 0)
                    : 0
                }

                return (
                  <th
                    style={{minWidth: `${item.width}px`, left: uu, zIndex: item.fixed ? 2 : 1}}
                    key={index}
                  >
                    {item.name}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              {
                zz = 0
              }
              return (
                <tr key={index}>
                  <>
                    {Object.keys(data[index]).map((item, i) => {
                      {
                        zz = headers[i].fixed ? (i ? (zz += headers[i - 1].width) : (zz += 0)) : 0
                      }

                      return (
                        <td
                          style={{
                            left: zz,
                            position: 'sticky',
                            zIndex: headers[i].fixed ? 1 : 0,
                            backgroundColor: headers[i].fixed ? '#b9b9b9' : '',
                          }}
                        >
                          {/*{headers[i].fixed ? i ? (zz += headers[i].width) : (zz += headers[i].width)
                            : ''}*/}

                          {headers[i].handler
                            ? headers[i].handler(data[index][item])
                            : data[index][item]}
                        </td>
                      )
                    })}
                  </>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export {BuyersPage}
