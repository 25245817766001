import React, {useEffect, useState} from 'react'
import axios from 'axios'
import FilesModal from './FilesModal'
import CreateFileDirectoryModal from './CreateFileDirectoryModal'
import {KTSVG} from '../../../../_metronic/helpers'

const AllFiles = ({type, title}) => {
  const [showCreateFileDirectoryModal, setShowCreateFileDirectoryModal] = useState(false)
  const [showFileModal, setShowFileModal] = useState(false)
  const [directoryId, setDirectoryId] = useState('')

  const [directories, setDirectories] = useState<any>([])

  useEffect(() => {
    if (!showCreateFileDirectoryModal && !showFileModal) {
      axios
        .post(process.env.REACT_APP_API_URL + '/v1/file-directory/get', {
          type: type,
        })
        .then((data) => {
          setDirectories(data.data)
        })
        .catch((err) => {
          console.log(err.response)
        })
    }
  }, [showCreateFileDirectoryModal, showFileModal])

  return (
    <>
      <CreateFileDirectoryModal
        showModal={showCreateFileDirectoryModal}
        setShowModal={setShowCreateFileDirectoryModal}
        type={type}
      />

      <FilesModal
        setShowModal={setShowFileModal}
        showModal={showFileModal}
        directoryId={directoryId}
      />

      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>{title}</h3>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  setShowCreateFileDirectoryModal(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Добавить папку
              </button>
            </div>
          </div>
        </div>

        {directories.length !== 0 ? (
          <div className='card-body'>
            <table className='table table-row-dashed table-row-gray-300 gy-2'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>Название</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {directories.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <span
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          setShowFileModal(true)
                          setDirectoryId(item.id)
                        }}
                      >
                        <img height={22} src={'/media/images/folder.png'} /> {item.name}
                      </span>
                    </td>
                    <td style={{textAlign: 'right'}}>
                      {item.files.length} <i className='fas fa-paperclip text-gray-900'></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div style={{textAlign: 'center', padding: 50}}>
            <span>У вас ещё нету добавленных папок !</span>
          </div>
        )}
      </div>
    </>
  )
}

export {AllFiles}
