import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import UserProfileModal from './components/UserProfileModal'
import {KTSVG} from '../../../_metronic/helpers'
import CreateUserModal from './components/CreateUserModal'

const UsersPage: FC = () => {
  const [users, setUsers] = useState<any>('')
  const [loadingRefresh, setLoadingRefresh] = useState(false)
  const [showUserProfileModal, setShowUserProfileModal] = useState(false)
  const [showCreateUserModal, setShowCreateUserModal] = useState(false)
  const [userId, setUserId] = useState('')
  let i = 0

  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/v1/users', {})
      .then((data) => {
        setUsers(data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const refreshUsersHandle = function () {
    setLoadingRefresh(true)
    axios
      .post(process.env.REACT_APP_API_URL + '/refresh-users', {})
      .then((data) => {
        setUsers(data.data)
      })
      .catch((err) => {
        console.log(err.response)
      })
      .finally(() => {
        setLoadingRefresh(false)
      })
  }

  return (
    <>
      <UserProfileModal
        setShowModal={setShowUserProfileModal}
        showModal={showUserProfileModal}
        userId={userId}
      />

      <CreateUserModal setShowModal={setShowCreateUserModal} showModal={showCreateUserModal} />

      {users && (
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Пользователи</h3>
            <div className='card-toolbar'>
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                <button
                  type='button'
                  className='btn btn-info'
                  onClick={() => {
                    setShowCreateUserModal(true)
                  }}
                  style={{marginRight: 5}}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                  Создать
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={refreshUsersHandle}
                  disabled={loadingRefresh}
                >
                  Обновить пользователей
                  {loadingRefresh && (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className='card-body'>
            <div className='table-responsive table table-row-dashed table-row-gray-300'>
              <table className='table gy-3 gs-7'>
                <thead>
                  <tr className='fw-bolder fs-6 text-gray-800'>
                    <th>{users.length}</th>
                    <th>ФИО</th>
                    <th></th>
                    <th></th>
                    <th>Email</th>
                    <th>Тел</th>
                    <th>Ext</th>
                    <th>Роль</th>
                    <th>Роль в crm</th>
                    <th>Code person</th>
                    <th>Телеграм</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((item) => (
                    <tr
                      key={item.id}
                      style={{backgroundColor: item.roles[0].name === 'admin' ? '#eadcdc' : ''}}
                    >
                      <td>{++i}</td>
                      <td>
                        <a
                          href={''}
                          onClick={(e) => {
                            e.preventDefault()
                            navigate('/users/' + item.id)
                          }}
                        >
                          {item.profile.fio}
                        </a>
                      </td>
                      <td>
                        <img
                          onClick={(e) => {
                            setShowUserProfileModal(true)
                            setUserId(item.id)
                          }}
                          style={{borderRadius: 30, cursor: 'pointer'}}
                          height={'20px'}
                          src={item.profile.avatar}
                        />
                      </td>
                      <td>
                        {item.profile.department_id && (
                          <img height={'20px'} src={'/media/icons/other/departments.png'} />
                        )}
                      </td>
                      <td>{item.profile.email}</td>
                      <td>{item.profile.phone}</td>
                      <td>{item.profile.ext}</td>
                      <td>{item.roles[0].name}</td>
                      <td>{item.profile.crm_role}</td>
                      <td>{item.profile.code_person}</td>
                      <td>{item.profile.telegram}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {UsersPage}
