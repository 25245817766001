import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import {useAuth} from '../../auth'

const SendTelegramModal = ({showModal, setShowModal, telegramId}) => {
  const {currentUser} = useAuth()
  const [text, setText] = useState('')
  const [disabled, setDisabled] = useState(false)

  const sendTelegramHandle = () => {
    if (text) {
      setDisabled(true)
      axios
        .post(process.env.REACT_APP_API_URL + '/v1/telegram/send', {
          id: telegramId,
          text: `У вас новое сообщение от: <b>${currentUser?.profile?.fio}</b>\n\n` + text,
        })
        .then((data) => {
          console.log(data.data)
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setText('')
          setShowModal(false)
          setDisabled(false)
        })
    }
  }

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-10'>
            <div>
              <label htmlFor='exampleFormControlTextarea1' className='form-label'>
                Текст сообщения
              </label>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder='Введите текст...'
                className='form-control bg-transparent'
                rows={10}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' variant='primary' onClick={sendTelegramHandle} disabled={disabled}>
            Отправить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SendTelegramModal
