import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import * as Yup from 'yup'
import {TextInput} from '../../../common_components/formik/TextInput'
import {useFormik} from 'formik'
import {Select} from '../../../common_components/formik/Select'
import OneErrorMessage from '../../errors/components/OneErrorMessage'

const CreatePersonModal = ({showModal, setShowModal}) => {
  const [departmentList, setDepartmentList] = useState([])
  const [error, setError] = useState('')

  useEffect(() => {
    if (showModal === true) {
      axios
        .get(process.env.REACT_APP_API_URL + '/v1/departments')
        .then((data) => {
          setDepartmentList(data.data)
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {})
    }
  }, [showModal])

  const createNewsSchema = Yup.object().shape({
    lastName: Yup.string().required('Фамилия обязательное поле').max(255, 'Не более 255 симоволов'),
    firstName: Yup.string().required('Имя обязательное поле').max(255, 'Не более 255 симоволов'),
    middleName: Yup.string().max(255, 'Не более 255 симоволов'),
    email: Yup.string().required('Email обязательное поле').email('Введите валидный Email'),
    phone: Yup.string().required('Телефон обязательное поле'),
    department: Yup.string().required('Отдел обязательное поле'),
  })

  const initialValues = {
    lastName: '',
    firstName: '',
    middleName: '',
    email: '',
    phone: '',
    department: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: createNewsSchema,
    onSubmit: async (values) => {
      setError('')
      await axios
        .post(process.env.REACT_APP_API_URL + '/v1/persons', {
          lastName: values.lastName,
          firstName: values.firstName,
          middleName: values.middleName,
          email: values.email,
          phone: values.phone,
          department: values.department,
        })
        .then((data) => {
          console.log(data)
          setShowModal(false)
          formik.resetForm()
        })
        .catch((err) => {
          //setLoading(false)
          console.log(err.response)
          console.log(err.response.data.message)
          setError(err.response.data.message)
          const response = err.response
          if (response && response.status === 422) {
            //setErrors(response.data.errors)
          }
        })
    },
  })

  const submitForm = function () {
    formik.handleSubmit()
  }

  return (
    <>
      <Modal dialogClassName='mw-75' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Создание сотрудника</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            {error && <OneErrorMessage>{error}</OneErrorMessage>}

            <div className='row'>
              <div className='col-sm'>
                <TextInput formik={formik} label={'Фамилия'} name={'lastName'} />
              </div>
              <div className='col-sm'>
                <TextInput formik={formik} label={'Имя'} name={'firstName'} />
              </div>
              <div className='col-sm'>
                <TextInput formik={formik} label={'Отчество'} name={'middleName'} />
              </div>
            </div>

            <div className='row' style={{marginTop: 20}}>
              <div className='col-sm'>
                <TextInput formik={formik} label={'Email'} name={'email'} />
              </div>
              <div className='col-sm'>
                <TextInput formik={formik} label={'Телефон'} name={'phone'} />
              </div>
              <div className='col-sm'>
                <Select
                  formik={formik}
                  label={'Отдел'}
                  name={'department'}
                  items={departmentList}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            variant='primary'
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={submitForm}
          >
            Создать
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreatePersonModal
