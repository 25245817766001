import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import NewsLogsModal from './components/NewsLogsModal'

const NewsLogs: FC = () => {
  const [logs, setLogs] = useState<any>([])
  const [showNewsLogsModal, setShowNewsLogsModal] = useState(false)
  const [newsId, setNewsId] = useState('')

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + '/v1/news/logs')
      .then((data) => {
        setLogs(data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <>
      <NewsLogsModal
        setShowModal={setShowNewsLogsModal}
        showModal={showNewsLogsModal}
        newsId={newsId}
      />

      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Логи новостей</h3>
        </div>

        <div className='card-body'>
          <table className='table table-row-dashed table-row-gray-300 gy-2'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>Новость</th>
                <th>Категория</th>
                <th style={{color: 'green', textAlign: 'center'}}>Прочитано</th>
                <th style={{color: 'red', textAlign: 'center'}}>Отправлено</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(logs).map((key, index) => (
                <tr key={index}>
                  <td>{logs[key].news_title}</td>
                  <td>{logs[key].category}</td>
                  <td
                    style={{
                      color: 'green',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      onClick={(e) => {
                        setShowNewsLogsModal(true)
                        setNewsId(key)
                      }}
                      style={{cursor: 'pointer'}}
                    >
                      {logs[key].read_count}
                    </span>
                  </td>
                  <td
                    onClick={(e) => {
                      setShowNewsLogsModal(true)
                      setNewsId(key)
                    }}
                    style={{
                      color: 'red',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      onClick={(e) => {
                        setShowNewsLogsModal(true)
                        setNewsId(key)
                      }}
                      style={{cursor: 'pointer'}}
                    >
                      {logs[key].send_count}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export {NewsLogs}
