import {useParams} from 'react-router-dom'
import {useEffect} from 'react'
import {getUserByToken} from './core/_requests'
import {useAuth} from './core/Auth'
import axios from 'axios'

export function Login2() {
  const params = useParams()
  const {setCurrentUser, saveAuth} = useAuth()

  const login2 = async () => {
    try {
      let response = await axios.post(process.env.REACT_APP_API_URL + '/login-or-register2', {
        token: params.id,
      })
      let auth: any = {}
      auth.api_token = response.data.token
      saveAuth(auth)
      let {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)
      if (response.data.redirect) {
        window.location.replace('/' + response.data.redirect)
      } else {
        window.location.replace('/')
      }
    } catch (error: any) {
      console.log(error.response)
      saveAuth(undefined)
    }
  }

  useEffect(() => {
    login2()
  }, [])

  return <></>
}
