import React, {useEffect, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import EditPersonModal from '../../buyers/components/EditPersonModal'
import CreatePersonModal from '../../buyers/components/CreatePersonModal'

const CreateUserModal = ({showModal, setShowModal}) => {
  const isInitialMount = useRef(true)
  const [name, setName] = useState('')
  const [persons, setPersons] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [showEditPersonModal, setShowEditPersonModal] = useState(false)
  const [showCreatePersonModal, setCreatePersonModal] = useState(false)
  const [personId, setPersonId] = useState(false)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (!showEditPersonModal) {
        axios
          .post(process.env.REACT_APP_API_URL + '/v1/persons/search', {
            name: name,
          })
          .then((data) => {
            setPersons(data.data)
          })
          .catch((err) => {
            console.log(err.response)
          })
          .finally(() => {})
      }
    }
  }, [showEditPersonModal])

  const submitFormHandle = (e) => {
    e.preventDefault()
    setPersons([])
    if (name) {
      setLoading(true)
      axios
        .post(process.env.REACT_APP_API_URL + '/v1/persons/search', {
          name: name,
        })
        .then((data) => {
          setPersons(data.data)
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const createUserHandle = (id) => {
    if (window.confirm('Уверены что хотите создать пользователя ?')) {
      alert(id)
    }
  }

  return (
    <>
      <EditPersonModal
        showModal={showEditPersonModal}
        setShowModal={setShowEditPersonModal}
        id={personId}
      />

      <CreatePersonModal setShowModal={setCreatePersonModal} showModal={showCreatePersonModal} />

      <Modal dialogClassName='mw-75' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Создание нового пользователя</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitFormHandle}>
            <div className='mb-10'>
              <label className='form-label'>Поиск</label>
              <input
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
                type='text'
                className='form-control'
                placeholder='Введите Фамилию или Email или Код покупателя...'
              />

              <button
                onClick={submitFormHandle}
                style={{float: 'right', marginTop: 10, marginLeft: 10}}
                type='button'
                className='btn btn-primary'
              >
                Искать
              </button>

              <button
                onClick={() => {setCreatePersonModal(true)}}
                style={{float: 'right', marginTop: 10}}
                type='button'
                className='btn btn-info'
              >
                Создать нового пользователя
              </button>
            </div>

            {loading && (
              <div className='text-center'>
                <div className='spinner-border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            )}

            {persons.length !== 0 && (
              <div style={{marginTop: 80}}>
                <table className='table table-row-dashed table-row-gray-300 gy-2'>
                  <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                      <th style={{backgroundColor: '#efefef'}}>&nbsp;&nbsp;Код</th>
                      <th style={{backgroundColor: '#efefef'}}>ФИО</th>
                      <th style={{backgroundColor: '#efefef'}}>Email</th>
                      <th style={{backgroundColor: '#efefef'}}>Тел</th>
                      <th
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#efefef',
                        }}
                      >
                        {persons.length} шт.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {persons.map((item) => (
                      <tr key={item.Code}>
                        <td>
                          &nbsp;&nbsp;
                          <span
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              setShowEditPersonModal(true)
                              setPersonId(item.Code)
                            }}
                          >
                            {item.Code}
                          </span>
                        </td>
                        <td style={{color: 'brown'}}>
                          <span
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              setShowEditPersonModal(true)
                              setPersonId(item.Code)
                            }}
                          >
                            {item.Name1} {item.Name2} {item.Name3}
                          </span>
                        </td>
                        <td>{item.Mail}</td>
                        <td style={{color: '#475d20'}}>{item.Phone}</td>
                        <td style={{textAlign: 'center'}}>
                          <button
                            style={{padding: '2px 15px'}}
                            type='button'
                            className='btn btn-primary btn-sm'
                            onClick={() => {
                              createUserHandle(item.Code)
                            }}
                          >
                            Создать
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CreateUserModal
