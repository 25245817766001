import React from 'react'

const OneErrorMessage = ({children}) => {
  return (
    <>
      <div className='alert alert-dismissible bg-light-danger border border-danger border-dashed d-flex flex-column flex-sm-row p-5 mb-10'>
        <div className='d-flex flex-column text-danger pe-0 pe-sm-10'>
          <h5 className='mb-1'></h5>
          <span>{children}</span>
        </div>
      </div>
    </>
  )
}

export default OneErrorMessage
