import React, {createRef, useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import {KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'
import Button from 'react-bootstrap/Button'
import OneErrorMessage from '../../errors/components/OneErrorMessage'

const FilesModal = ({showModal, setShowModal, directoryId}) => {
  const [loading, setLoading] = useState(false)
  const [directory, setDirectory] = useState<any>(false)
  const [upload, setUpload] = useState<any>(false)
  const [error, setError] = useState<any>(false)
  const fileUpload = createRef<any>()

  const {currentUser} = useAuth()

  useEffect(() => {
    setError(false)
    if (showModal || upload) {
      setLoading(true)
      axios
        .post(process.env.REACT_APP_API_URL + '/v1/file-directory/get', {
          id: directoryId,
        })
        .then((data) => {
          setDirectory(data.data)
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [showModal, upload])

  const showFileUpload = () => {
    fileUpload.current.click()
  }

  const onFileChange = (event) => {
    let formData = new FormData()

    setUpload(false)

    formData.append('file', event.target.files[0])

    axios
      .post(
        process.env.REACT_APP_API_URL + '/v1/file-directory/upload-file/' + directoryId,
        formData
      )
      .then((data) => {})
      .catch((err) => {
        console.log(err.response)
      })
      .finally(() => {
        setUpload(true)
      })
  }

  function deleteFileHandler(id) {
    if (window.confirm('Уверены что хотите удалить файл ?')) {
      setUpload(false)
      axios
        .post(process.env.REACT_APP_API_URL + '/v1/file-directory/delete-file', {
          id: id,
        })
        .then((data) => {})
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setUpload(true)
        })
    }
  }

  function deleteDirectoryHandler() {
    if (window.confirm('Уверены что хотите удалить папку ?')) {
      if (directory?.files?.length !== 0) {
        setError('Сперва удалите все файлы из этой папки')
      } else {
        axios
          .post(process.env.REACT_APP_API_URL + '/v1/file-directory/delete-directory', {
            id: directory.id,
          })
          .then((data) => {})
          .catch((err) => {
            console.log(err.response)
          })
          .finally(() => {
            setShowModal(false)
          })
      }
    }
  }

  return (
    <>
      <Modal dialogClassName='mw-75' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
              <img height={25} style={{marginRight: 5}} src={'/media/images/folder.png'} />
            {directory.name}
            {directory?.user_id === currentUser?.id && (
              <i
                onClick={deleteDirectoryHandler}
                style={{marginLeft: 5, cursor: 'pointer'}}
                className='fa-solid fa-trash fs-5 text-danger'
              ></i>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input style={{display: 'none'}} type='file' ref={fileUpload} onChange={onFileChange} />

          <div className='row'>
            <div className='container'>
              <div className='row'>
                <div className='col'>
                  <button
                    style={{float: 'right'}}
                    type='button'
                    className='btn btn-primary'
                    onClick={showFileUpload}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    Добавить файл
                  </button>
                </div>
              </div>
            </div>
          </div>

          {error && (
            <div className='row' style={{marginTop: 20}}>
              <div className='container'>
                <div className='row'>
                  <div className='col-sm'>
                    <OneErrorMessage>{error}</OneErrorMessage>
                  </div>
                </div>
              </div>
            </div>
          )}

          {loading ? (
            <div className='text-center'>
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <table className='table table-row-dashed table-row-gray-300 gy-2'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>Название</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {directory?.files?.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <a
                        target={'_blank'}
                        href={`https://portal-api.portobello.ru/uploads/files/${directoryId}/${item.full_name}`}
                        style={{color: '#333'}}
                      >
                        <img height={22} src={'/media/images/file.png'} /> {item.full_name}
                      </a>
                    </td>
                    <td>
                      {currentUser?.id === item.user_id && (
                        <i
                          onClick={() => deleteFileHandler(item.id)}
                          style={{float: 'right', marginRight: 5, cursor: 'pointer'}}
                          className='fa-solid fa-trash fs-5 text-danger'
                        ></i>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FilesModal
