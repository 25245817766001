import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {TextInput} from '../../../common_components/formik/TextInput'
import {Select} from '../../../common_components/formik/Select'
import {TextArea} from '../../../common_components/formik/TextArea'
import Button from 'react-bootstrap/Button'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Errors} from '../../../common_components/laravel/Errors'
import axios from 'axios'
import ImageUploading, {ImageListType} from 'react-images-uploading'

interface CreateEditModalProps {
  showModal: any
  closeModal: any
  news: any
  categories: any
  refreshNewsHandler: any
  departments: any
}

export function EditNewsModal2({
  showModal,
  closeModal,
  news,
  categories,
  refreshNewsHandler,
  departments,
}: CreateEditModalProps) {
  {
    const [errors, setErrors] = useState<any>(false)
    const [images, setImages] = useState([])
    const [departmentError, setDepartmentError] = useState(false)

    const [options, setOptions] = useState<any>([])

    const [selectedOptions, setSelectedOptions] = useState<any>([])

    useEffect(() => {
      let arr = []
      departments.map((item) => {
        if (news.departments.find((obj) => obj.id === item.id)) {
          // @ts-ignore
          arr.push({id: item.id, name: item.name, checked: true})
        } else {
          // @ts-ignore
          arr.push({id: item.id, name: item.name, checked: false})
        }
      })

      setOptions(arr)
    }, [showModal])

    //console.log(departments)

    const onChange = (imageList: ImageListType) => {
      setErrors(false)
      let formData: any = new FormData()
      formData.append('image', imageList[0].file, imageList[0].file?.name)

      axios
        .post(process.env.REACT_APP_API_URL + '/v1/news/upload-img/' + news.id, formData)
        .then((data) => {
          imageList[0].dataURL = data.data.url
          setImages(imageList as never[])
          //let element: any = document.getElementById('preview_img');
          //element.src = data.data.url

          refreshNewsHandler(news, data.data.url)

          console.log(data.data.url)
        })
        .catch((err) => {
          console.log(err.response)
          const response = err.response
          if (response && response.status === 422) {
            setErrors(response.data.errors)
          }
        })
    }

    const createNewsSchema = Yup.object().shape({
      title: Yup.string()
        .required('Загаловок обязательное поле')
        .max(255, 'Не более 255 симоволов'),
      category_id: Yup.number().required('Категория обязательное поле'),
      text: Yup.string().required('Описание обязательное поле'),
    })

    const initialValues = {
      title: news.title || '',
      category_id: news.category?.id || '',
      text: news.text || '',
    }

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: createNewsSchema,
      enableReinitialize: true,

      onSubmit: async (values) => {
        setErrors(false)
        await axios
          .put(process.env.REACT_APP_API_URL + '/v1/news/' + news.id, {
            title: values.title,
            category_id: values.category_id,
            text: values.text,
            departments: selectedOptions,
          })
          .then((data) => {
            console.log(data)
            closeModal()
            formik.resetForm()
          })
          .catch((err) => {
            console.log(err.response)
            const response = err.response
            if (response && response.status === 422) {
              setErrors(response.data.errors)
            }
          })
      },
    })

    const submitForm = function () {
      formik.handleSubmit()
    }

    const handleCheck = (e) => {
      let arr = []

      options.map((obj) => {
        if (obj.id == e.target.value) {
          obj.checked = e.target.checked
          // @ts-ignore
          arr.push(obj)
        } else {
          // @ts-ignore
          arr.push(obj)
        }
      })

      setOptions(arr)

      // @ts-ignore
      setSelectedOptions(arr.filter((n) => n.checked))
    }

    const publishNews = () => {
      if (window.confirm('Уверены что хотите опубликовать новость ?')) {
        if (formik.isValid) {
          axios
            .post(process.env.REACT_APP_API_URL + '/v1/news/publish-news', {
              id: news.id,
            })
            .then((data) => {
              console.log(data)
            })
            .catch((err) => {
              console.log(err.response)
            })
            .finally(() => {
              closeModal()
              formik.resetForm()
            })
        }
      }
    }

      const unpublishNews = () => {
          if (window.confirm('Уверены что хотите снять с публикации новость ?')) {
              if (formik.isValid) {
                  axios
                      .post(process.env.REACT_APP_API_URL + '/v1/news/unpublish-news', {
                          id: news.id,
                      })
                      .then((data) => {
                          console.log(data)
                      })
                      .catch((err) => {
                          console.log(err.response)
                      })
                      .finally(() => {
                          closeModal()
                          formik.resetForm()
                      })
              }
          }
      }

    return (
      <>
        <Modal fullscreen={true} show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Редактирование новости</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errors && <Errors errors={errors} />}

            <ImageUploading value={images} onChange={onChange}>
              {({onImageUpload, isDragging, dragProps}) => (
                // write your building UI
                <div className='upload__image-wrapper'>
                  <button
                    className={
                      'btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary mb-5'
                    }
                    style={isDragging ? {color: 'red'} : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <span className='svg-icon svg-icon-1'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='mh-50px'
                      >
                        <path
                          opacity='0.3'
                          d='M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z'
                          fill='black'
                        ></path>
                        <path
                          d='M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z'
                          fill='black'
                        ></path>
                      </svg>
                    </span>
                    Загрузить превью
                  </button>
                  <div className='image-item mb-5'>
                    <img id={'preview_img'} src={news.img} alt='' width='100' />
                    <div className='image-item__btn-wrapper'></div>
                  </div>
                </div>
              )}
            </ImageUploading>

            <form onSubmit={formik.handleSubmit} noValidate>
              <div className='mb-10'>
                <TextInput formik={formik} label={'Заголовок'} name={'title'} />
              </div>

              <div className='mb-10'>
                <Select
                  formik={formik}
                  label={'Категория'}
                  name={'category_id'}
                  items={categories}
                />
              </div>

              {departmentError && (
                <div className='alert alert-danger mb-9' role='alert'>
                  Выберите хотя бы один отдел и сохраните изменения !
                </div>
              )}

              {options.map((option) => (
                <div key={option.id} className='mb-3'>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`department_select_${option.id}`}
                      name={''}
                      checked={option.checked}
                      onChange={handleCheck}
                      value={option.id}
                    />
                    <label
                      style={{cursor: 'pointer', color: '#1c649f', fontSize: 15}}
                      className='form-check-label'
                      htmlFor={`department_select_${option.id}`}
                    >
                      {option.name}
                    </label>
                  </div>
                </div>
              ))}

              <div className='mb-10 mt-10'>
                <TextArea formik={formik} label={'Описание'} name={'text'} rows={15} />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={closeModal}>
              Закрыть
            </Button>
            {news?.departments?.length !== 0 && news?.status === 0 ? (
              <Button type='submit' variant='danger' onClick={publishNews}>
                Опубликовать
              </Button>
            ) : (
              ''
            )}

            {news?.status === 1 && (
              <Button type='submit' variant='danger' onClick={unpublishNews}>
                Снять с публикации
              </Button>
            )}

            <Button
              type='submit'
              variant='primary'
              disabled={formik.isSubmitting || !formik.isValid}
              onClick={submitForm}
            >
              Сохранить
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
