import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import parse from 'html-react-parser'
import moment from 'moment'

const NewsOne: FC = () => {
  const [news, setNews] = useState<any>({})
  const [comment, setComment] = useState<string>('')
  const [comments, setComments] = useState<object[]>([])
  const params = useParams()

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/v1/news/' + params.id, {})
      .then((data) => {
        setNews(data.data)
        setComments(data.data.comments)
        console.log(data.data)
      })
      .catch((err) => {
        console.log(err)
      })

      axios
          .post(process.env.REACT_APP_API_URL + '/v1/news/read-news', {
              id: params.id,
          })
          .then((data) => {
              console.log(data)
          })
          .catch((err) => {
              console.log(err.response)
          })
          .finally(() => {
          })


  }, [])

  const sendMessage = (e) => {
    e.preventDefault()
    if (comment) {
      setComment('')
      axios
        .post(process.env.REACT_APP_API_URL + '/v1/news/add-comment', {
          text: comment,
          id: news.id,
        })
        .then((data) => {
          console.log(data.data)
          setComments((prevState) => [...prevState, data.data])
        })
        .catch((err) => {
          console.log(err.response)
        })
    }
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-body'>
          <h1 className={'mb-10'}>{news.title}</h1>
          <div>{news.text && parse(news.text)}</div>
        </div>
      </div>

      <form onSubmit={sendMessage}>
        <div className='card card-custom'>
          <div className='card-body'>
            <h2 className={'mb-10'}>Комментарии</h2>
            {comments.map((item: any) => (
              <div key={item.id} className='d-flex d-flex justify-content-start mb-10 mb-10'>
                <div className='d-flex flex-column align-items align-items-start'>
                  <div className='d-flex align-items-center mb-2'>
                    <div className='symbol  symbol-35px symbol-circle '></div>
                    <div className='ms-3'>
                      <a className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                        {item.user.name}
                      </a>
                      <span className='text-muted fs-7 mb-1'>
                        {moment(item.created_at).format('DD-MM-YY HH:mm')}
                      </span>
                    </div>
                  </div>
                  <div
                    className='p-5 rounded bg-light-info text-dark fw-bold text-start'
                    data-kt-element='message-text'
                  >
                    {item.text}
                  </div>
                </div>
              </div>
            ))}
            <div className='mt-10 mb-20'>
              <input
                type='text'
                className='form-control mb-5'
                placeholder='коментарий...'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button type='submit' className='btn btn-primary'>
                Добавить коментарий
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export {NewsOne}
