import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import axios from 'axios'
import {TextInput} from '../../../common_components/formik/TextInput'

const DepartmentCreateModal = ({showModal, setShowModal}) => {
  const createNewsSchema = Yup.object().shape({
    name: Yup.string().required('Обязательное поле').max(255, 'Не более 255 симоволов'),
  })

  const initialValues = {
    name: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: createNewsSchema,
    onSubmit: async (values) => {
      await axios
        .post(process.env.REACT_APP_API_URL + '/v1/departments', {
          name: values.name,
        })
        .then((data) => {
          //console.log(data)
          //setLoading(false)
          //closeModal()
          formik.resetForm()
          setShowModal(false)
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setShowModal(false)
        })
    },
  })

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Добавить отдел</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='mb-10'>
              <TextInput formik={formik} label={'Название отдела'} name={'name'} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Закрыть
          </Button>
          <Button
            type='submit'
            variant='primary'
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => formik.handleSubmit()}
          >
            Добавить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DepartmentCreateModal
