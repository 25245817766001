import React from 'react'

interface ErrorsProps {
  errors: any
}

export function Errors({errors}: ErrorsProps) {
  return (
    <>
      <div className='alert bg-light-danger border border-danger border-dashed d-flex flex-column flex-sm-row p-5 mb-10 fade-in'>
        <div className='d-flex flex-column text-danger pe-0 pe-sm-10'>
          {Object.keys(errors).map((key) => (
            <span key={key}>{errors[key][0]}</span>
          ))}
        </div>
      </div>
    </>
  )
}
