import React from 'react'
import clsx from 'clsx'

interface TextAreaProps {
  formik: any
  label: string
  name: string
  rows: number
}

export function TextArea({formik, label, name, rows}: TextAreaProps) {
  return (
    <>
      <label htmlFor='exampleFormControlTextarea1' className='form-label'>
        {label}
      </label>
      <textarea
        placeholder='Введите текст...'
        className={clsx(
          'form-control bg-transparent',
          {'is-invalid': formik.touched[name] && formik.errors[name]},
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}
        {...formik.getFieldProps({name})}
        rows={rows}
      ></textarea>
      {formik.touched[name] && formik.errors[name] && (
        <div className='fv-plugins-message-container text-danger'>
          <span role='alert'>{formik.errors[name]}</span>
        </div>
      )}
    </>
  )
}
