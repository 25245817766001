import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import Button from 'react-bootstrap/Button'

const EditPersonModal = ({showModal, setShowModal, id}) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    if (showModal === true) {
      setLoading(true)

      axios
        .post(process.env.REACT_APP_API_URL + '/v1/persons/search', {
          id: id,
        })
        .then((data) => {
          setLastName(data.data[0].Name1)
          setFirstName(data.data[0].Name2)
          setMiddleName(data.data[0].Name3)
          setEmail(data.data[0].Mail)
          setPhone(data.data[0].Phone)
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [showModal])

  const submitForm = () => {
      setDisabled(true)
    axios
      .put(process.env.REACT_APP_API_URL + '/v1/persons', {
        id: id,
        lastName: lastName,
        firstName: firstName,
        middleName: middleName,
        email: email,
        phone: phone,
      })
      .then((data) => {
          setShowModal(false)
      })
      .catch((err) => {
        console.log(err.response)
      })
      .finally(() => {
        setLoading(false)
          setDisabled(false)
      })
  }

  return (
    <>
      <Modal dialogClassName='mw-75' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              Карточка редактирования покупателя - <span style={{color: 'brown'}}>{id}</span>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className='text-center'>
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='container'>
              <div className='row'>
                <div className='col-sm'>
                  <label className='form-label'>Фамилия</label>
                  <input
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                    type='text'
                    className='form-control'
                    placeholder='Фамилия'
                  />
                </div>
                <div className='col-sm'>
                  <label className='form-label'>Имя</label>
                  <input
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                    }}
                    type='text'
                    className='form-control'
                    placeholder='Имя'
                  />
                </div>
                <div className='col-sm'>
                  <label className='form-label'>Отчество</label>
                  <input
                    value={middleName}
                    onChange={(e) => {
                      setMiddleName(e.target.value)
                    }}
                    type='text'
                    className='form-control'
                    placeholder='Отчество'
                  />
                </div>
              </div>

              <div className='row' style={{marginTop: 20}}>
                <div className='col-sm'>
                  <label className='form-label'>Email</label>
                  <input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                    type='text'
                    className='form-control'
                    placeholder='Фамилия'
                  />
                </div>
                <div className='col-sm'>
                  <label className='form-label'>Телефон</label>
                  <input
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value)
                    }}
                    type='text'
                    className='form-control'
                    placeholder='Фамилия'
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            variant='primary'
            disabled={disabled}
            onClick={submitForm}
          >
            Изменить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditPersonModal
