import React, {useState} from 'react'
import axios from 'axios'

const Phone = ({children, phone, ext}) => {
  const [loading, setLoading] = useState<any>(false)

  const phoneHandle = () => {
    setLoading(true)

    axios
      .post(process.env.REACT_APP_API_URL + '/v1/telephony/call', {
        ext: ext,
        phone: phone,
      })
      .then((data) => {
        console.log(data)
      })
      .catch((err) => {
        console.log(err.response)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <span onClick={phoneHandle}>
      {loading ? (
        <div className='text-center'>
          <div className='spinner-border' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        children
      )}
    </span>
  )
}

export default Phone
