import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {useAuth} from '../auth'
import {useNavigate} from 'react-router-dom'
import UserProfileModal from '../users/components/UserProfileModal'
import Phone from '../users/components/Phone'
import SendTelegramModal from '../telegram/components/SendTelegramModal'
import {KTSVG} from '../../../_metronic/helpers'
import CreateUserModal from '../users/components/CreateUserModal'
import UserEditModal from '../users/components/UserEditModal'

const DepartmentsUserPage = () => {
  const [departments, setDepartments] = useState<any>([])
  const [users, setUsers] = useState<any>([])
  const [firedUsers, setFiredUsers] = useState<any>([])
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [showUserProfileModal, setShowUserProfileModal] = useState(false)
  const [showSendTelegramModal, setShowSendTelegramModal] = useState(false)
  const [showCreateUserModal, setShowCreateUserModal] = useState(false)
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [userId, setUserId] = useState('')
  const [telegramId, setTelegramId] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    if (!showCreateUserModal && !showEditUserModal) {
      axios
        .get(process.env.REACT_APP_API_URL + '/v1/departments', {})
        .then((data) => {
          setDepartments(data.data)
        })
        .catch((err) => {
          console.log(err.response)
        })
    }
  }, [showCreateUserModal, showEditUserModal])

  useEffect(() => {
    if (!showCreateUserModal && !showEditUserModal) {
      if (currentUser?.role === 'admin') {
        axios
          .get(process.env.REACT_APP_API_URL + '/v1/users')
          .then((data) => {
            setUsers(data.data)
          })
          .catch((err) => {
            console.log(err)
          })

        axios
          .get(process.env.REACT_APP_API_URL + '/v1/users?is_fired=1')
          .then((data) => {
            setFiredUsers(data.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }, [showCreateUserModal, showEditUserModal])

  const dataForSearch = JSON.parse(JSON.stringify(departments))

  const filtered = dataForSearch.filter(
    (item) =>
      (item.user_profiles = item.user_profiles.filter((item2) => {
        return (
          item2.fio?.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
          item2.ext?.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
          item2.phone?.toLowerCase().includes(searchQuery.toLowerCase().trim())
        )
      }))
  )

  return (
    <>
      <UserProfileModal
        setShowModal={setShowUserProfileModal}
        showModal={showUserProfileModal}
        userId={userId}
      />

      <SendTelegramModal
        showModal={showSendTelegramModal}
        setShowModal={setShowSendTelegramModal}
        telegramId={telegramId}
      />

      <CreateUserModal setShowModal={setShowCreateUserModal} showModal={showCreateUserModal} />

      <UserEditModal
        showModal={showEditUserModal}
        setShowModal={setShowEditUserModal}
        userId={userId}
      />

      <div className='card card-custom'>
        <div className='card-body'>
          {currentUser?.role === 'admin' && (
            <button
              type='button'
              className='btn btn-info btn-sm'
              onClick={() => {
                setShowCreateUserModal(true)
              }}
              style={{marginBottom: 20, float: 'right'}}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Создать нового сотрудника
            </button>
          )}

          <div className='table-responsive table table-row-dashed table-row-gray-300'>
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{marginBottom: 25}}
              type='text'
              className='form-control'
              placeholder='Поиск...'
            />

            <table className='table gy-1 gs-2'>
              <tbody>
                {filtered.map((department) => {
                  return (
                    <React.Fragment key={department.id}>
                      {department.user_profiles.length !== 0 && (
                        <>
                          <tr key={department.id} style={{backgroundColor: '#e1e1e1'}}>
                            <td colSpan={100}>
                              <b style={{color: '#4641ad'}}>
                                {department.name}{' '}
                                <span style={{color: 'brown'}}>
                                  ({department.user_profiles.length})
                                </span>
                              </b>
                            </td>
                          </tr>

                          {department.user_profiles.map((user) => {
                            return (
                              <tr key={user.id}>
                                <td width={400}>
                                  &nbsp;
                                  <img
                                    onClick={(e) => {
                                      setShowUserProfileModal(true)
                                      setUserId(user.user_id)
                                    }}
                                    style={{borderRadius: 30, cursor: 'pointer'}}
                                    height={'20px'}
                                    src={user.avatar}
                                  />
                                  &nbsp;
                                  {currentUser?.role === 'admin' ? (
                                    <span
                                      onClick={(e) => {
                                        setShowEditUserModal(true)
                                        setUserId(user.user_id)
                                      }}
                                      style={{
                                        borderRadius: 30,
                                        cursor: 'pointer',
                                      }}
                                    >
                                      {user.fio}
                                    </span>
                                  ) : (
                                    <span>{user.fio}</span>
                                  )}
                                </td>
                                <td width={170} style={{color: '#4641ad'}}>
                                  {user.phone && (
                                    <Phone phone={user.phone} ext={currentUser?.profile?.ext}>
                                      <span style={{cursor: 'pointer'}}>
                                        <img
                                          style={{height: 15, float: 'left'}}
                                          src='/media/images/phone.png'
                                        />
                                        {'+' + user.phone}
                                      </span>
                                    </Phone>
                                  )}
                                </td>
                                <td width={80} style={{color: '#5e6048'}}>
                                  {user.ext && <b>{user.ext}</b>}
                                </td>

                                <td width={60} style={{color: '#5e6048'}}>
                                  {user.telegram && (
                                    <img
                                      onClick={(e) => {
                                        setShowSendTelegramModal(true)
                                        setTelegramId(user.telegram)
                                      }}
                                      style={{borderRadius: 30, cursor: 'pointer'}}
                                      height={'20px'}
                                      src={'/media/icons/other/telegram1.png'}
                                    />
                                  )}
                                </td>

                                <td style={{color: '#67670e'}}>
                                  {user.email && (
                                    <a style={{color: '#333'}} href={`mailto:${user.email}`}>
                                      <img
                                        style={{marginRight: 5}}
                                        height={'16px'}
                                        src={'/media/images/email2.png'}
                                      />
                                      {user.email}
                                    </a>
                                  )}
                                </td>

                                {currentUser?.role === 'admin' && (
                                  <td style={{color: '#67670e', float: 'right'}}>
                                    {user.code_person ? (
                                      <span>{user.code_person}</span>
                                    ) : (
                                      <b style={{color: '#ee4646'}}>Отсутствует</b>
                                    )}
                                  </td>
                                )}
                              </tr>
                            )
                          })}
                        </>
                      )}
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {currentUser?.role === 'admin' && (
        <div className='card card-custom mt-10'>
          <div className='card-body'>
            <div className='table-responsive table table-row-dashed table-row-gray-300'>
              <table className='table gy-1 gs-2'>
                <tbody>
                  {users.map((user) => {
                    return (
                      !user.profile.department_id && (
                        <tr key={user.id}>
                          <td colSpan={100}>
                            <span
                              style={{cursor: 'pointer'}}
                              onClick={(e) => {
                                setShowEditUserModal(true)
                                setUserId(user.id)
                              }}
                            >
                              <img
                                style={{borderRadius: 30}}
                                height={'20px'}
                                src={user.profile.avatar}
                              />
                              &nbsp; &nbsp;
                              {user.profile.fio}
                            </span>
                          </td>
                        </tr>
                      )
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {currentUser?.role === 'admin' && (
        <div className='card card-custom mt-10'>
          <div className='card-body'>
            <div className='table-responsive table table-row-dashed table-row-gray-300'>
              <table className='table gy-1 gs-2'>
                <tbody>
                  {firedUsers.map((user) => {
                    return (
                      1 && (
                        <tr key={user.id}>
                          <td colSpan={100}>
                            <span
                              style={{cursor: 'pointer', color: 'red'}}
                              onClick={(e) => {
                                setShowEditUserModal(true)
                                setUserId(user.id)
                              }}
                            >
                              <img
                                style={{borderRadius: 30}}
                                height={'20px'}
                                src={user.profile.avatar}
                              />
                              &nbsp; &nbsp;
                              {user.profile.fio}
                            </span>
                          </td>
                        </tr>
                      )
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DepartmentsUserPage
