import React, {useEffect, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'

const UserProfileModal = ({showModal, setShowModal, userId}) => {
  const isInitialMount = useRef(true)
  const [user, setUser] = useState<any>({})
  const [loading, setLoading] = useState<any>(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (showModal === true) {
        setLoading(true)
        setUser({})

        axios
          .get(process.env.REACT_APP_API_URL + '/v1/users/' + userId)
          .then((data) => {
            setUser(data.data)
            setLoading(false)
          })
          .catch((err) => {
            console.log(err.response)
          })
          .finally(() => {})
      }
    }
  }, [showModal])

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className='text-center'>
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='mb-10'>
              <div style={{textAlign: 'center'}}>
                <img height={'240px'} src={user?.profile?.avatar} />

                <h3 style={{marginTop: 20}}>{user?.profile?.fio}</h3>
                <p style={{marginTop: 10, fontSize: 16}}>
                  <img height={'22px'} src='/media/images/email.png' /> {user?.profile?.email}
                </p>
                <p style={{marginTop: 10, fontSize: 16}}>
                  <img height={'22px'} src='/media/images/phone.png' /> {user?.profile?.phone}
                </p>

                {user?.profile?.department && (
                  <p
                    style={{
                      marginTop: 10,
                      fontSize: 16,
                      color: 'brown',
                    }}
                  >
                    {user?.profile?.department?.name}
                  </p>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserProfileModal
