import React from 'react'
import clsx from 'clsx'

interface TextInputProps {
  formik: any
  label: string
  name: string
}

export function TextInput({formik, label, name}: TextInputProps) {
  return (
    <>
      <label className='form-label'>{label}</label>
      <input
        {...formik.getFieldProps({name})}
        type='text'
        className={clsx(
          'form-control bg-transparent',
          {'is-invalid': formik.touched[name] && formik.errors[name]},
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}
        placeholder='Введите значение...'
      />
      {formik.touched[name] && formik.errors[name] && (
        <div className='fv-plugins-message-container text-danger'>
          <span role='alert'>{formik.errors[name]}</span>
        </div>
      )}
    </>
  )
}
