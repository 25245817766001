import React, {FC, useEffect, useState} from 'react'

import {KTSVG} from '../../../_metronic/helpers'

import axios from 'axios'

import {EditNewsModal2} from './components/EditNewsModal2'
import {CreateNewsModal2} from './components/CreateNewsModal2'

const NewsEditPage: FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [categories, setCategories] = useState([])
  const [departments, setDepartments] = useState<any>([])
  const [news, setNews] = useState<any>([])
  const [loading, setLoading] = useState(true)

  const [currentNews, setCurrentNews] = useState<any>({})

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/v1/news-categories', {})
      .then((data) => {
        setCategories(data.data.data)
        setLoading(false)
        console.log(data.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/v1/departments')
      .then((data) => {
        setDepartments(data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    console.log('zzzzzzzzzzzzzzzzzzz')
    axios
      .get(process.env.REACT_APP_API_URL + '/v1/news')
      .then((data) => {
        setNews(data.data.data)
        console.log(data.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [showModal, showModal2])

  const handleClose = function () {
    setShowModal(false)
  }

  const handleShow = function () {
    setShowModal(true)
  }

  const handleClose2 = function () {
    setShowModal2(false)
  }

  const handleCurrentNews = function (item: any) {
    setCurrentNews(item)
    setShowModal2(true)
  }

  const refreshNewsHandler = function (newsOne, img) {
    /*news.map(function (item) {

                    item.id == newsOne.id
                        ? {...item, title: 'tesssss1'}
                        : item

                })*/

    setCurrentNews((prevState) =>
      prevState.id === newsOne.id ? {...currentNews, img: img} : prevState
    )

    //setCurrentNews(item)

    /* item.id == newsOne.id
                     ? {...item, title: 'tesssss1'}
                     : item*/

    //console.log(newsOne)
    //console.log(img)
    //console.log(1111)
  }

  return (
    <>
      <EditNewsModal2
        showModal={showModal2}
        closeModal={handleClose2}
        news={currentNews}
        categories={categories}
        refreshNewsHandler={refreshNewsHandler}
        departments={departments}
      />
      <CreateNewsModal2 showModal={showModal} closeModal={handleClose} categories={categories} />

      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Новости</h3>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <button type='button' className='btn btn-primary' onClick={handleShow}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Добавить новость
              </button>
            </div>
          </div>
        </div>

        <div className='card-body'>
          <table className='table table-row-dashed table-row-gray-300 gy-2'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>Заголовок</th>
                <th>Категория</th>
                <th>Дата создания</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <th colSpan={111}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  </th>
                </tr>
              )}

              {news.map((item: any) => (
                <tr key={item.id}>
                  <td>
                    <span onClick={() => handleCurrentNews(item)} style={{cursor: 'pointer'}}>
                      {item.title}
                    </span>
                  </td>
                  <td>{item.category.name}</td>
                  <td>{item.created_at}</td>
                  <td>
                    {item.status === 1 && <span style={{color: 'green'}}>Опубликована</span>}
                    {item.status === 0 && <span style={{color: 'red'}}>Не опубликована</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export {NewsEditPage}
