/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
//import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()

  const {currentUser} = useAuth()

  return (
    <>
      {currentUser?.role === 'admin' ? (
        <SidebarMenuItemWithSub
          to='/settings'
          title='Настройки'
          icon='/media/icons/duotune/coding/cod001.svg'
          fontIcon='bi-person'
        >
          <SidebarMenuItem to='/users/all' title='Пользователи' hasBullet={true} />
          <SidebarMenuItem to='/news/edit' title='Новости' hasBullet={true} />
          <SidebarMenuItem to='/news/logs' title='Логи новостей' hasBullet={true} />
          <SidebarMenuItem to='/departments/all' title='Отделы' hasBullet={true} />
        </SidebarMenuItemWithSub>
      ) : (
        ''
      )}

      <SidebarMenuItem
        to='/news/all'
        title='Новости'
        icon='/media/icons/duotune/general/gen023.svg'
        fontIcon='bi-person'
      />

      <SidebarMenuItem
        to='/departments/user'
        title='Сотрудники'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      />

      <SidebarMenuItemWithSub
        to='/files'
        title='Файлы'
        icon='/media/icons/duotune/files/fil005.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/files/all' title='Общедоступные' hasBullet={true} />
        <SidebarMenuItem to='/files/my' title='Персональные' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
