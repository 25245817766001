import React, {useEffect, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'

const NewsLogsModal = ({showModal, setShowModal, newsId}) => {
  const isInitialMount = useRef(true)
  const [logs, setLogs] = useState<any>([])
  const [loading, setLoading] = useState<any>(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (showModal === true) {
        setLoading(true)
        setLogs([])

        axios
          .post(process.env.REACT_APP_API_URL + '/v1/news/one-log', {
            id: newsId,
          })
          .then((data) => {
            setLogs(data.data)
            setLoading(false)
          })
          .catch((err) => {
            console.log(err.response)
          })
          .finally(() => {})
      }
    }
  }, [showModal])

  return (
    <>
      <Modal dialogClassName='mw-75' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{logs[Object.keys(logs)[0]]?.news}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className='text-center'>
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='mb-10'>
              <table className='table table-row-dashed table-row-gray-300 gy-2'>
                <thead>
                  <tr className='fw-bolder fs-6 text-gray-800'>
                    <th></th>
                    <th style={{color: 'green', textAlign: 'center'}}>Прочитано</th>
                    <th style={{color: 'red', textAlign: 'center'}}>Отправлено</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(logs).map((key, index) => (
                    <tr key={key}>
                      <td>{logs[key].fio}</td>
                      <td style={{color: 'green', textAlign: 'center'}}>{logs[key].read_count}</td>
                      <td style={{color: 'red', textAlign: 'center'}}>{logs[key].send_count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default NewsLogsModal
