import React, {useEffect, useState} from 'react'
import DepartmentCreateModal from './components/DepartmentCreateModal'
import axios from 'axios'
import DepartmentEditModal from './components/DepartmentEditModal'
import {KTSVG} from '../../../_metronic/helpers'

const DepartmentsPage = () => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [departments, setDepartments] = useState<any>([])
  const [department, setDepartment] = useState<any>({})
  const [currentDepartmentSort, setCurrentDepartmentSort] = useState<any>({})

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/v1/departments')
      .then((data) => {
        setDepartments(data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [showCreateModal, showEditModal])

  function onDragStartHandle(e, item) {
    setCurrentDepartmentSort(item)
  }

  function onDragLeaveHandle(e) {
    e.preventDefault()
    e.target.style.background = '#fff'
  }

  function onDragOverHandle(e) {
    e.preventDefault()
    e.target.style.background = '#dadada'
  }

  async function onDropHandle(e, item) {
    e.preventDefault()
    e.target.style.background = '#fff'
    let arr: any = []
    await setDepartments(
      departments.map((one) => {
        if (one.id === item.id) {
          arr.push({...one, sort: currentDepartmentSort.sort})
          return {...one, sort: currentDepartmentSort.sort}
        }
        if (one.id === currentDepartmentSort?.id) {
          arr.push({...one, sort: item.sort})
          return {...one, sort: item.sort}
        }
        arr.push(one)
        return one
      })
    )

    await axios
      .post(process.env.REACT_APP_API_URL + '/v1/departments/sort-edit', {
        data: arr,
      })
      .then((data) => {
        console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const sortItems = (a, b) => {
    if (a.sort > b.sort) {
      return 1
    } else {
      return -1
    }
  }

  return (
    <>
      <DepartmentCreateModal showModal={showCreateModal} setShowModal={setShowCreateModal} />
      <DepartmentEditModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        item={department}
      />

      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Отделы</h3>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => setShowCreateModal(true)}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Добавить отдел
              </button>
            </div>
          </div>
        </div>

        <div className='card-body'>
          <div className='table-responsive table table-row-dashed table-row-gray-300'>
            <table className='table gy-3 gs-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th>Название</th>
                </tr>
              </thead>
              <tbody>
                {departments.sort(sortItems).map((item) => (
                  <tr key={item.id}>
                    <td
                      draggable={true}
                      onDragStart={(e) => onDragStartHandle(e, item)}
                      onDragLeave={(e) => onDragLeaveHandle(e)}
                      onDragOver={(e) => onDragOverHandle(e)}
                      onDrop={(e) => onDropHandle(e, item)}
                    >
                      <span style={{cursor: 'pointer'}}>
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs015.svg'
                          className='svg-icon-2'
                        />
                      </span>
                      &nbsp; &nbsp;
                      <a
                        onClick={(e) => {
                          e.preventDefault()
                          setShowEditModal(true)
                          setDepartment(item)
                        }}
                        href={''}
                      >
                        {item.name}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default DepartmentsPage
