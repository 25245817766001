import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import axios from 'axios'
import {TextInput} from '../../../common_components/formik/TextInput'
import {Select} from '../../../common_components/formik/Select'

const UserEditModal = ({showModal, setShowModal, userId}) => {
  const [departmentList, setDepartmentList] = useState([])
  const [rolesList, setRolesList] = useState([])
  const [user, setUser] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const createNewsSchema = Yup.object().shape({
    firstName: Yup.string().required('Обязательное поле'),
    lastName: Yup.string().required('Обязательное поле'),
    phone: Yup.string().required('Обязательное поле'),
    role: Yup.string().required('Обязательное поле'),
  })

  let roleId = ''

  if (user?.roles) {
    roleId = user.roles[0].id
  }

  const initialValues = {
    firstName: user?.profile?.first_name || '',
    lastName: user?.profile?.last_name || '',
    middleName: user?.profile?.middle_name || '',
    is_fired: user?.profile?.is_fired || '',
    phone: user?.profile?.phone || '',
    ext: user?.profile?.ext || '',
    department: user?.profile?.department_id || '',
    role: roleId || '',
  }

  const firedList = [
    {id: 'yes', name: 'Уволен'},
    {id: 'no', name: 'Не уволен'},
  ]

  useEffect(() => {
    if (showModal) {
      setLoading(true)
      axios
        .get(process.env.REACT_APP_API_URL + '/v1/users/' + userId, {})
        .then((data) => {
          setUser(data.data)
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [showModal])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/v1/departments')
      .then((data) => {
        setDepartmentList(data.data)
      })
      .catch((err) => {
        console.log(err.response)
      })
      .finally(() => {})

    axios
      .post(process.env.REACT_APP_API_URL + '/v1/users/roles-list')
      .then((data) => {
        console.log(data.data)
        setRolesList(data.data)
      })
      .catch((err) => {
        console.log(err.response)
      })
      .finally(() => {})
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: createNewsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await axios
        .put(process.env.REACT_APP_API_URL + '/v1/users/' + user.id, {
          department: values.department,
          role: values.role,
          first_name: values.firstName,
          last_name: values.lastName,
          middle_name: values.middleName,
          phone: values.phone,
          ext: values.ext,
          is_fired: values.is_fired,
        })
        .then((data) => {})
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          formik.resetForm()
          setShowModal(false)
        })
    },
  })

  return (
    <>
      <Modal dialogClassName='mw-75' show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Редактирование пользователя</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className='text-center'>
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                <div className='col-sm'>
                  <TextInput formik={formik} label={'Фамилия'} name={'lastName'} />
                </div>

                <div className='col-sm'>
                  <TextInput formik={formik} label={'Имя'} name={'firstName'} />
                </div>
                <div className='col-sm'>
                  <TextInput formik={formik} label={'Отчество'} name={'middleName'} />
                </div>
                <div className='col-sm'>
                  <TextInput formik={formik} label={'Телефон'} name={'phone'} />
                </div>
              </div>

              <div className='row' style={{marginTop: 10}}>
                <div className='col-sm'>
                  <Select
                    formik={formik}
                    label={'Отдел'}
                    name={'department'}
                    items={departmentList}
                  />
                </div>
                <div className='col-sm'>
                  <Select formik={formik} label={'Роль'} name={'role'} items={rolesList} />
                </div>

                <div className='col-sm'>
                  <Select
                    formik={formik}
                    label={'Увольнение'}
                    name={'is_fired'}
                    items={firedList}
                  />
                </div>

                <div className='col-sm'>
                  <TextInput formik={formik} label={'Ext'} name={'ext'} />
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Закрыть
          </Button>
          <Button
            type='submit'
            variant='primary'
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => formik.handleSubmit()}
          >
            Редактировать
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UserEditModal
