import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {TextInput} from '../../../common_components/formik/TextInput'
import {Select} from '../../../common_components/formik/Select'
import {TextArea} from '../../../common_components/formik/TextArea'
import Button from 'react-bootstrap/Button'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import axios from 'axios'
import {Errors} from '../../../common_components/laravel/Errors'

interface CreateNewsModalProps {
  showModal: any
  closeModal: any
  categories: any
}

export function CreateNewsModal2({showModal, closeModal, categories}: CreateNewsModalProps) {
  {
    const [errors, setErrors] = useState<any>(false)

    const createNewsSchema = Yup.object().shape({
      title: Yup.string()
        .required('Загаловок обязательное поле')
        .max(255, 'Не более 255 симоволов'),
      category_id: Yup.number().required('Категория обязательное поле'),
      text: Yup.string().required('Описание обязательное поле'),
    })

    const initialValues = {
      title: '',
      category_id: '',
      text: '',
    }

    const formik = useFormik({
      initialValues,
      validationSchema: createNewsSchema,
      onSubmit: async (values) => {
        setErrors(false)
        await axios
          .post(process.env.REACT_APP_API_URL + '/v1/news', {
            title: values.title,
            category_id: values.category_id,
            text: values.text,
          })
          .then((data) => {
            console.log(data)
            //setLoading(false)
            closeModal()
            formik.resetForm()
          })
          .catch((err) => {
            //setLoading(false)
            console.log(err.response)
            const response = err.response
            if (response && response.status === 422) {
              setErrors(response.data.errors)
            }
          })
      },
    })

    const submitForm = function () {
      formik.handleSubmit()
    }

    return (
      <>
        <Modal fullscreen={true} show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit} noValidate>
              {errors && <Errors errors={errors} />}

              <div className='mb-10'>
                <TextInput formik={formik} label={'Заголовок'} name={'title'} />
              </div>

              <div className='mb-10'>
                <Select
                  formik={formik}
                  label={'Категория'}
                  name={'category_id'}
                  items={categories}
                />
              </div>

              <div className='mb-10'>
                <TextArea formik={formik} label={'Описание'} name={'text'} rows={15} />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={closeModal}>
              Закрыть
            </Button>
            <Button
              type='submit'
              variant='primary'
              disabled={formik.isSubmitting || !formik.isValid}
              onClick={submitForm}
            >
              Добавить
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
