import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'

const NewsPage: FC = () => {
  const [news, setNews] = useState<any>([])
  const navigate = useNavigate()

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + '/v1/news/for-user')
      .then((data) => {
        setNews(data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  let arr: any = []

  news.map((item, index) => {
    if (!arr[item.category.id]) {
      arr[item.category.id] = []
    }

    arr[item.category.id].push(item)
  })

  return (
    <>
      <div className='card card-custom'>
        <div className='card-body'>
          {arr.map((one, index) => {
            return (
              <div key={index} className={'mb-15'}>
                <h1 className={'mb-5'}>{one[0].category.name}</h1>
                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4 g-5'>
                  {one.map((item) => {
                    return (
                      <div
                        key={item.id}
                        style={{cursor: 'pointer'}}
                        onClick={() => navigate(`/news/${item.id}`)}
                        className='col'
                      >
                        <div
                          style={{height: '300px', overflow: 'hidden'}}
                          className='card shadow-sm'
                        >
                          {item.is_read === 'no' && (
                            <span
                              style={{
                                position: 'absolute',
                                right: 0,
                                backgroundColor: '#d34569',
                                padding: '4px',
                                color: '#fff',
                                fontSize: '15px',
                              }}
                            >
                              Не прочитано
                            </span>
                          )}

                          <div style={{height: '200px'}}>
                            <img
                              className={'img-fluid'}
                              style={{maxWidth: 'auto', minHeight: 'auto'}}
                              src={item.img}
                            />
                          </div>
                          <div className='card-body'>
                            <h4 className='card-text'>{item.title}</h4>
                            <i
                              style={{
                                float: 'right',
                                fontSize: '16px',
                                position: 'absolute',
                                right: 10,
                                bottom: 10,
                              }}
                              className='bi bi-chat-left-text text-gray-600'
                            >
                              <span style={{paddingLeft: '3px'}}>{item.comments.length}</span>
                            </i>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export {NewsPage}
