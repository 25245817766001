import React from 'react'
import clsx from 'clsx'

interface SelectProps {
  formik: any
  label: string
  name: string
  items: {
    id: string | number
    name: string | number
  }[]
}

export function Select({formik, label, name, items}: SelectProps) {
  return (
    <>
      <label className='form-label'>{label}</label>
      <select
        className={clsx(
          'form-select bg-transparent',
          {'is-invalid': formik.touched[name] && formik.errors[name]},
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}
        {...formik.getFieldProps({name})}
      >
        <option disabled value=''>
          Выберите из списка
        </option>
        {items.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>

      {formik.touched[name] && formik.errors[name] && (
        <div className='fv-plugins-message-container text-danger'>
          <span role='alert'>{formik.errors[name]}</span>
        </div>
      )}
    </>
  )
}
